/**
  * Page Blocks
*/

const Utils = require('./utils.js');

(function($, window, document, undefined) { // eslint-disable-line no-unused-vars
  'use strict';

  const pageBlocksNew = {

    loadBlocks() {
      // $('.c-page__blocks').stop().animate({
      //   opacity: 1
      // })
    },

    slider() {
      const sliders = $('.c-block__slider.owl-carousel');

      $.each(sliders, (i, el) => {
        const slider = $(el),
          slide           = slider.find('.c-block__slider-slide'),
          spinner         = slider.parent().find('.c-block__slider-spinner'),
          count           = slide.length,
          type            = slider.attr('data-slider-type'),
          animation       = slider.attr('data-slider-animation'),
          autoplayTime    = slider.attr('data-slider-autoplay'),
          autoplayPause   = slider.attr('data-slider-autoplay-pause'),
          loop            = slider.attr('data-slider-loop'),
          margin          = slider.attr('data-slider-margin'),
          center          = slider.attr('data-slider-center'),
          dots            = slider.attr('data-slider-dots'),
          arrows          = slider.attr('data-slider-arrows'),
          items           = slider.attr('data-slider-items');

        const showNav = function(navContainer) {
          if( count > 1 ) {
            if( arrows == 'on' ) {
              navContainer.removeClass('disabled');
            }
          }
        }

        const videoPosterHeight = function() {
          const videoBox = slider.find('.c-block__slider-slide-video--vimeo, .c-block__slider-slide-video--youtube, .c-block__slider-slide-video--mp4');
          if( videoBox.length ) {
            if( slider.find('.item-image').length ) {
              videoBox.css('height', slider.find('.item-image').first().parent().height());
            }else {
              videoBox.css('height', videoBox.parent().height());
            }
          }
        }

        if( !slider.find('.item-image').length ) {
          spinner.hide();
        }

        slider.find('.item-image').on('lazyloaded', () => {
          spinner.hide();
        });

        slider.on('initialized.owl.carousel', () => {
          setTimeout( () => {
            const navContainer = slider.find('.owl-nav');

            showNav(navContainer);
            slider.trigger( 'refresh.owl.carousel' );
          }, 100);

          videoPosterHeight();

          let debounce;
          $(window).on('resize', () => {
            clearTimeout(debounce);

            debounce = setTimeout( () => {
              slider.trigger( 'refresh.owl.carousel' );
            }, 250);
          });
        });

        slider.on('initialized.owl.carousel changed.owl.carousel', (e) => {
          const index = e.item.index,
            slide     = slider.find('.c-block__slider-slide').eq(index),
            video     = slide.find('.c-block__slider-slide-video'),
            hasVideo  = video.length;

          if( hasVideo ) {
            if( video.hasClass('c-block__slider-slide-video--youtube') ) {
              const playBtn = slide.find('.owl-video-play-icon');

              if( playBtn.length ) {
                playBtn.on('click', () => {
                  setTimeout( () => {
                    const iframe  = video.find('iframe'),
                      src         = iframe.attr('src'),
                      rel         = video.attr('data-yt-rel') || 0,
                      controls    = video.attr('data-yt-controls') || 1,
                      info        = video.attr('data-yt-info') || 1,
                      fullscreen  = video.attr('data-yt-fullscreen') || 0;

                    const srcParams = new URLSearchParams(src),
                      videoParams = {'rel' : rel, 'controls' : controls, 'showinfo' : info};

                    $.each(videoParams, (k, v) => {
                      if( srcParams.has(k) ) {
                        srcParams.set(k, v);
                      }else {
                        srcParams.append(k, v);
                      }
                    });

                    const newSrc = srcParams.toString();
                    iframe.attr('src', decodeURIComponent( newSrc ) );
                    iframe.attr('allowfullscreen', fullscreen );
                  }, 200);
                });
              }
            }
          }
        });

        const showItems   = items > count ? count : parseFloat( items );

        let responsiveData = {
          0: {
            items: 1,
            margin: parseFloat( margin )
          },
        }

        let breakpoints = [0];

        switch(showItems) {
        case 2:
          breakpoints = [0, 861];
          break;
        case 3:
          breakpoints = [0, 769, 1153];
          break;
        case 4:
          breakpoints = [0, 641, 861, 1153];
          break;
        case 5:
          breakpoints = [0, 541, 769, 961, 1153];
          break;
        case 6:
          breakpoints = [0, 481, 641, 861, 1025, 1281];
          break;
        case 7:
          breakpoints = [0, 441, 641, 861, 993, 1153, 1281];
          break;
        case 8:
          breakpoints = [0, 441, 641, 861, 1025, 1153, 1201, 1281];
          break;
        }

        for(let i=0; i<showItems; i++) {
          responsiveData[breakpoints[i]] = {
            items: i + 1,
            margin: parseFloat( margin )
          }
        }

        slider.owlCarousel({
          nav: arrows == 'on',
          navText: ['<svg class="icon icon-chevron-left"><use xlink:href="#c-icon--chevron-left"></use></svg>', '<svg class="icon icon-chevron-right"><use xlink:href="#c-icon--chevron-right"></use></svg>'],
          dots: dots == 'on',
          navSpeed: 1000,
          dotsSpeed: 1000,
          center: center == 'yes',
          autoplaySpeed: 1000,
          loop: loop == 'yes',
          autoplay: autoplayTime > 0,
          autoplayTimeout: parseFloat( autoplayTime ),
          autoplayHoverPause: autoplayPause == 'yes',
          animateOut: animation == 'fade' && type == 'slider' ? 'fadeOut' : false,
          video: true,
          autoHeight:true,
          mouseDrag: false,
          responsive: responsiveData
        });

        slider.on('change.owl.carousel', (e) => {
          const item = (e.item.index),
            //slide = $(e.target).find('.c-block__slider-slide'),
            video = $(e.target).find('.c-block__slider-slide').eq(item).find('video'),
            playBtn = $(e.target).find('.video-play-btn');

          playBtn.hide();

          if( video.length ) {
            if( !video[0].paused ) {
              video[0].pause();
              video[0].currentTime = 0;
              video[0].load();
              //playBtn.show();
            }
          }
        });

        slider.on('resized.owl.carousel translated.owl.carousel', () => {
          videoPosterHeight();
        });

        slider.on('translate.owl.carousel', () => {
          slider.find('.c-block__slider-slide-caption').fadeOut(200);
        });

        slider.on('translated.owl.carousel', () => {
          const navContainer = slider.find('.owl-nav');

          slider.find('.active .c-block__slider-slide-caption').fadeIn(300);

          if( count > 1 ) {
            if( arrows == 'on' ) {
              showNav(navContainer);
            }
          }
        });
      });
    },

    gallery() {
      const galleries = $('.c-block__gallery.owl-carousel');

      if( !galleries.length ) {
        return;
      }

      // const itemOnEdge = gallery => {
      //   const items = gallery.find('.c-block__gallery-item'),
      //     positions = [];

      //   items.removeClass('c-block__gallery-item--right-edge c-block__gallery-item--left-edge');

      //   $.each(items, (i, el) => {
      //     const item = $(el),
      //       posLeft = item.position().left;

      //     positions.push(posLeft);
      //   });

      //   const maxPos = Math.max( ...positions ),
      //     minPos = Math.min( ...positions );

      //   $.each(items, (i, el) => {
      //     const item = $(el),
      //       posLeft = item.position().left;

      //     if( posLeft == maxPos ) {
      //       item.addClass('c-block__gallery-item--right-edge');
      //     }else if( posLeft == minPos ) {
      //       item.addClass('c-block__gallery-item--left-edge');
      //     }
      //   });
      // }

      $.each(galleries, (i, el) => {
        const gallery = $(el),
          lazy_load = gallery.find('.c-block__gallery-page').length > 1 && gallery.hasClass('c-block__gallery--masonry') ? false : true;

        gallery.on('initialized.owl.carousel', () => {

          if( gallery.hasClass('c-block__gallery--masonry') ) {
            const masonry = gallery.find('.c-block__gallery-page');

            if( masonry.length ) {
              setTimeout( () => {
                masonry.masonry({
                  itemSelector: '.c-block__gallery-item',
                });
                masonry.stop().animate({
                  opacity: 1
                }, 300);
                Utils.galleryItemOnEdge(gallery);
              }, 500);
            }
          }else {
            setTimeout( () => {
              Utils.galleryItemOnEdge(gallery);
            }, 100);
          }

          gallery.find('.owl-stage-outer').css('transition', 'none');
        });

        gallery.owlCarousel({
          items: 1,
          nav: false,
          dotsEach: true,
          dotsSpeed: 1000,
          lazyLoad: lazy_load,
          autoHeight: true,
          lazyLoadEager: 2,
        });

        gallery.on('change.owl.carousel', () => {
          gallery.find('.owl-stage-outer').css('transition', '1s');
        });

        gallery.on('resized.owl.carousel', () => {
          if( gallery.hasClass('c-block__gallery--masonry') ) {
            const masonry = gallery.find('.c-block__gallery-page');

            if( masonry.length ) {
              masonry.masonry({
                itemSelector: '.c-block__gallery-item',
              });
              setTimeout( () => {
                Utils.galleryItemOnEdge(gallery);
              }, 100);
            }
          }else {
            setTimeout( () => {
              Utils.galleryItemOnEdge(gallery);
            }, 100);
          }
        });
      });
    },

    tabs() {
      const tabsBlocks = $('.c-block__tabs--tabs');

      if( !tabsBlocks.length ) {
        return false;
      }

      $.each(tabsBlocks, (index, element) => {
        const tab = $(element),
          tabsNav = tab.find('.c-block__tabs-nav'),
          tabsNavLink = tabsNav.find('li a'),
          tabsContent = tab.find('.c-block__tabs-tab');

        tabsNavLink.on('click', function(e) {
          e.preventDefault();

          const link = $(this),
            target = link.attr('href'),
            tabContent = tab.find(target),
            speed = 500;

          tabsNavLink.attr('aria-expanded', false).parent().removeClass('active');
          link.attr('aria-expanded', true).parent().addClass('active');

          tabsContent.hide().removeClass('in');
          tabContent.fadeIn(speed).addClass('in');
        });
      });
    },

    accordion() {
      const accordionBlocks = $('.c-block__tabs--accordion');

      if( !accordionBlocks.length ) {
        return false;
      }

      $.each(accordionBlocks, (index, element) => {
        const accordion = $(element),
          header            = accordion.find('.c-block__tabs-accordion-header'),
          headerLink        = header.find('a'),
          accordionsContent = accordion.find('.c-block__tabs-accordion-content');

        headerLink.on('click', function(e) {
          e.preventDefault();

          const link = $(this),
            target = link.attr('href'),
            accordionContent = accordion.find(target);

          header.removeClass('active');
          headerLink.attr('aria-expanded', false);
          link.attr('aria-expanded', true).parent().addClass('active');

          if( accordionContent.hasClass('in') ) {
            accordionContent.slideUp(400, function() {
              accordionContent.removeClass('in');
              link.parent().removeClass('active');
            });
          }else {
            accordionsContent.slideUp(400);
            accordionContent.slideDown(400, function() {
              accordionsContent.removeClass('in');
              accordionContent.addClass('in');
            });
          }
        });
      });
    },

    testimonials() {
      const carousel = $('.c-block__testimonials.owl-carousel');

      carousel.on('initialized.owl.carousel translate.owl.carousel', function(e) {
        let count = e.item.count,
          current = e.item.index + 1;

        setTimeout( () => {
          const nav = carousel.find('.owl-nav');

          nav.find('.owl-prev').removeClass('invisible');
          nav.find('.owl-next').removeClass('invisible');

          if( current == 1 ) {
            nav.find('.owl-prev').addClass('invisible');
          }

          if( current == count ) {
            nav.find('.owl-next').addClass('invisible');
          }
        }, 10);
      });

      // carousel.on('initialized.owl.carousel translate.owl.carousel resize.owl.carousel', function() {
      //   const item = $('.c-page-block__testimonials-carousel .c-page-block__testimonials-item');

      //   item.css('min-height', '1px');

      //   const maxHeight = Utils.getMaxHeight( '.c-page-block__testimonials-carousel .owl-item');

      //   item.css('min-height', maxHeight);
      // });

      carousel.on('initialized.owl.carousel', () => {
        setTimeout( () => {
          const arrows = carousel.find('.owl-nav');

          arrows.removeClass('disabled');
        }, 10);
      });

      carousel.owlCarousel({
        items: 1,
        nav: true,
        navText: ['<svg class="icon icon-chevron-left"><use xlink:href="#c-icon--chevron-left"></use></svg>', '<svg class="icon icon-chevron-right"><use xlink:href="#c-icon--chevron-right"></use></svg>'],
        navSpeed: 1000,
        dots: false,
        margin: 0
      });

      carousel.on('changed.owl.carousel', () => {
        setTimeout( () => {
          const arrows = carousel.find('.owl-nav');

          arrows.removeClass('disabled');
        }, 10);
      });
    },

    instagram() {
      const widget = $('.c-block__social-media-widget--instagram');

      if( !widget.length ) {
        return;
      }

      $.each(widget, (i, el) => {
        const instafeed = $(el).find('.c-block__social-media-widget-instafeed'),
          id            = instafeed.attr('id'),
          at            = instafeed.attr('data-insta-at'),
          data          = at ? at.split('.') : '',
          uid           = data.length > 0 ? data[0] : '',
          caption       = instafeed.attr('data-insta-caption'),
          limit         = instafeed.attr('data-insta-limit') || 10,
          img_size      = instafeed.attr('data-insta-image-size'),
          res           = img_size == 'large' ? 'standard_resolution' : 'low_resolution';

        const feed = new Instafeed({ // eslint-disable-line no-undef
          get: 'user',
          userId: uid,
          target: id,
          accessToken: at,
          resolution: res,
          limit: limit,
          template: caption == 'yes' ? '<a target="_blank" href="{{link}}"><img src="{{image}}" /><span>{{caption}}</span></a>' : '<a target="_blank" href="{{link}}"><img src="{{image}}" /></a>',
          error: function(msg) {
            console.log(msg)
          }
        });

        feed.run();
      });
    },

    columnsSameHeight( block ) {
      const blockColumn = block.find('.c-block__columns-column-inner');

      blockColumn.css('min-height', '1px');

      let maxHeight = Utils.getMaxHeight(blockColumn);

      blockColumn.css('min-height', maxHeight);
    },

    columnsElementsSameHeight( block, reset = 768 ) {
      if( !block.hasClass('c-block__columns--equal-elements') ) {
        return;
      }

      block.find('p').filter( (i, el) => {
        return $(el).html().match(/&nbsp;/);
      }).remove();

      const elements = [ '.c-block__columns-column-image', '.c-block__columns-column-title', '.c-block__columns-column-meta', '.c-block__columns-column-text'];

      Utils.equalHeightElements( elements, block, reset );
    },

    columnsBlock() {
      const columns = $('.c-block__columns');

      if( !columns.length ) {
        return;
      }

      $.each(columns, (i, el) => {
        const block = $(el);

        if( block.hasClass('owl-carousel') ) {
          return;
        }

        $(document).on('lazyloaded', () => {
        //this.imagesLoaded( block ).then( () => {
          this.columnsElementsSameHeight( block );

          if( block.find('.c-block__columns-column-inner--equal').length ) {
            Utils.equalHeightElements( ['.c-block__columns-column-inner'], block, 768 );
          }
        });

        $(window).on('resize', () => {
          this.columnsElementsSameHeight( block );
          if( block.find('.c-block__columns-column-inner--equal').length ) {
            Utils.equalHeightElements( ['.c-block__columns-column-inner'], block, 768 );
          }
        });
      });
    },

    columnsAnimationsCustom() {
      const customContentPost = $('.c-block__columns-column-inner-post--animation');

      if (!customContentPost.length) {
        return;
      }

      $.each(customContentPost, (i, el) => {
        const box = $(el);
        const title = box.find('.c-block__columns-column-title');
        const text  = box.find('.c-block__columns-column-text');
        const image  = box.find('.c-block__columns-column-image');
        const btn  = box.find('.c-block__columns-column-btn');

        if ((!title.length && !text.length) || !image.length) {
          return;
        }

        image.on('lazyloaded', () => {
          // if (btn.length) {
          //   if (text.length) {
          //     btn.appendTo(text);
          //   } else if (title.length) {
          //     btn.appendTo(title);
          //   }
          // }
          let titleHeight = title.length ? title.outerHeight(true) : 0;
          let textHeight = text.length ? text.outerHeight(true) : 0;
          let btnHeight = btn.length ? btn.outerHeight(true) : 0;
          let imageHeight = image.outerHeight();
          let minBoxHeight = titleHeight + textHeight + btnHeight + 10;

          if (imageHeight < minBoxHeight) {
            box.css('height', minBoxHeight);
            image.find('img').css({
              'height': minBoxHeight,
              'max-width': 'none'
            });
          }else {
            box.css('height', 'auto');
            image.find('img').css({
              'height': 'auto',
              'max-width': '100%'
            });
          }

          let boxHeight = box.outerHeight(true);

          let titlePercent = (titleHeight * 100) / boxHeight;
          let bottom = 100 - titlePercent;

          $(window).on('resize', () => {
            titleHeight = title.length ? title.outerHeight(true) : 0;
            textHeight = text.length ? text.outerHeight(true) : 0;
            let btnHeight = btn.length ? btn.outerHeight(true) : 0;
            minBoxHeight = titleHeight + textHeight + 10;

            box.css('height', titleHeight + textHeight + btnHeight + 10);

            if (imageHeight < minBoxHeight) {
              box.css('height', minBoxHeight);
              image.find('img').css({
                'height': minBoxHeight,
                'max-width': 'none'
              });
            }else {
              box.css('height', 'auto');
              image.find('img').css({
                'height': 'auto',
                'max-width': '100%'
              });
            }

            boxHeight = box.outerHeight(true);

            titlePercent = (titleHeight * 100) / boxHeight;
            bottom = 100 - titlePercent;
          });

          box.hover( () => {
            if (title.length) {
              title.stop().animate({
                bottom: `${bottom}%`
              }, 300);
            }

            if (text.length) {
              text.stop().delay(200).animate({
                top: `${titlePercent}%`
              }, 300);
            }

            if (btn.length) {
              btn.stop().delay(300).animate({
                bottom: 0,
              }, 300);
            }
          }, () => {
            if (title.length) {
              title.stop().delay(200).animate({
                bottom: 20
              }, 300);
            }

            if (text.length) {
              text.stop().delay(100).animate({
                top: '100%'
              }, 300);
            }

            if (btn.length) {
              btn.stop().animate({
                bottom: '-100%',
              }, 300);
            }
          });
        });
      });
    },

    imagesLoaded( block ) {
      const images = block.find('img');
      const dfd = $.Deferred();
      const length = images.length;

      let loaded = 0;

      $.each(images, (i, el) => {
        const image = $(el);

        image.on('load', () => {
          loaded ++;

          if( loaded == length ) {
            dfd.resolve(loaded);
          }
        });
      });

      return dfd;
    },

    columnsBlockCarousel() {
      const columns = $('.c-block__columns.owl-carousel');

      $.each(columns, (i, el) => {
        const block = $(el);

        const showItems = block.attr('data-owl-items') || 4,
          loop          = block.attr('data-owl-loop') || 0,
          nav           = block.attr('data-owl-nav') || 1,
          dots          = block.attr('data-owl-dots') || 0,
          autoplayOn    = block.attr('data-owl-autoplay') || 0,
          autoplayTime  = block.attr('data-owl-autoplay-time') * 1000 || 5000,
          slideByCols   = block.attr('data-owl-slideby') || 1,
          reset         = 530;

        const slideByItems = slideByCols > showItems ? showItems : slideByCols;

        let responsiveData = {
          0: {
            items: 1,
            margin: 0
          },
          530: {
            items: showItems > 2 ? 2 : showItems,
          },
          860: {
            items: showItems > 3 ? 3 : showItems,
          },
          1152: {
            items: showItems
          }
        }

        const showNav = ( carousel ) => {
          if( nav == 1 ) {
            carousel.addClass('owl-show-nav')
          }
        }

        block.on('initialized.owl.carousel', e => {
          const carousel = $(e.target);

          this.columnsElementsSameHeight( carousel, reset );
          Utils.equalHeightElements( ['.c-block__columns-column-inner'], carousel, reset );

          showNav(carousel);
        });

        block.owlCarousel({
          loop: loop == 1,
          nav: nav == 1,
          navText: ['<svg class="icon icon-chevron-left"><use xlink:href="#c-icon--chevron-left"></use></svg>', '<svg class="icon icon-chevron-right"><use xlink:href="#c-icon--chevron-right"></use></svg>'],
          navSpeed: 1000,
          dotsSpeed: 1000,
          autoplaySpeed: 1000,
          dots: dots == 1,
          lazyLoad: true,
          autoplay: autoplayOn == 1,
          autoplayTimeout: autoplayTime,
          autoplayHoverPause: true,
          responsive: responsiveData,
          slideBy: slideByItems
        });

        block.on('resized.owl.carousel', e => {
          const carousel = $(e.target);

          setTimeout( () => {
            this.columnsElementsSameHeight( carousel, reset );
            Utils.equalHeightElements( ['.c-block__columns-column-inner'], carousel, reset );
          }, 200);
        });

        block.on('drag.owl.carousel', e => {
          e.relatedTarget.settings.slideBy = slideByItems > 1 ? slideByItems - 1 : 1;
        });

        block.on('dragged.owl.carousel', e => {
          const carousel = $(e.target);
          const direction = e.relatedTarget._drag.direction;

          if (autoplayOn == 0) {
            carousel.trigger('stop.owl.autoplay');
          }

          if (direction == 'left') {
            carousel.trigger('next.owl.carousel', [1000]);
          } else {
            carousel.trigger('prev.owl.carousel', [1000]);
          }

          e.relatedTarget.settings.slideBy = slideByItems;
        });
      });
    },

    columns() {
      const columns = $('.c-block__columns');

      if( !columns.length ) {
        return;
      }

      const levelContent = function(container, onlazyloaded = false) {
        if( !container.hasClass('c-block__columns--equal-elements') ) {
          return;
        }

        container.find('p').filter( (i, el) => {
          return $(el).html().match(/&nbsp;/);
        }).addClass('u-margin-nbsp');

        function level(container) {
          const items = ['header', 'title', 'image', 'text', 'meta'];

          for(let i=0; i<items.length; i++) {
            const item = container.find(`.c-block__columns-column-${items[i]}`);

            if( item.length ) {
              item.css('min-height', '1px');
              let maxHeight = Utils.getMaxHeight(item);
              item.css('min-height', maxHeight);
            }
          }
          container.trigger( 'refresh.owl.carousel' );
        }

        if( onlazyloaded ) {
          $(document).on('lazyloaded', () => {
            level(container);
          });
        }else {
          level(container);
        }

        $(window).on('resize', () => {
          level(container);
        });
      }

      function sameHeightColumns(block) {
        const blockColumn = block.find('.c-block__columns-column-inner');

        blockColumn.css('min-height', '1px');

        let maxHeight = Utils.getMaxHeight(blockColumn);

        blockColumn.css('min-height', maxHeight);
      }

      $.each(columns, (i, el) => {
        const block = $(el);

        sameHeightColumns(block);

        let debounce;
        $(window).on('resize', () => {
          clearTimeout(debounce);

          debounce = setTimeout( () => {
            sameHeightColumns(block);
          }, 250);
        });

        if( block.hasClass('owl-carousel') ) {

          const showItems = block.attr('data-owl-items') || 4,
            loop          = block.attr('data-owl-loop') || 0,
            nav           = block.attr('data-owl-nav') || 1,
            dots          = block.attr('data-owl-dots') || 0,
            autoplay      = block.attr('data-owl-autoplay') || 0,
            autoplayTime  = block.attr('data-owl-autoplay-time') * 1000 || 5000;

          let responsiveData = {
            0: {
              items: 1,
              margin: 0
            },
            530: {
              items: showItems > 2 ? 2 : showItems,
            },
            860: {
              items: showItems > 3 ? 3 : showItems,
            },
            1152: {
              items: showItems
            }
          }

          const showNav = function(navContainer) {
            if( nav == 1 ) {
              navContainer.removeClass('disabled');
            }
          }

          block.on('initialized.owl.carousel', (e) => {
            const carousel  = $(e.target);
            levelContent( carousel, true );

            block.on('lazyloaded', () => {
              setTimeout( () => {
                sameHeightColumns(block);
              }, 500);
            });

            setTimeout( () => {
              const navContainer = block.find('.owl-nav');

              showNav(navContainer);
              block.trigger( 'refresh.owl.carousel' );
            }, 100);
          });

          block.owlCarousel({
            loop: loop == 1,
            nav: nav == 1,
            navText: ['<svg class="icon icon-chevron-left"><use xlink:href="#c-icon--chevron-left"></use></svg>', '<svg class="icon icon-chevron-right"><use xlink:href="#c-icon--chevron-right"></use></svg>'],
            navSpeed: 1000,
            dotsSpeed: 1000,
            autoplaySpeed: 1000,
            dots: dots == 1,
            lazyLoad: false,
            autoplay: autoplay == 1,
            autoplayTimeout: autoplayTime,
            autoplayHoverPause: true,
            responsive: responsiveData
          });

          block.on('changed.owl.carousel', () => {
            const navContainer = block.find('.owl-nav');

            showNav(navContainer);
          });
        }else {
          levelContent( block );
        }
      });
    },

    columnsLoadMore() {
      const columns = $('.c-block__columns');

      if( !columns.length ) {
        return;
      }

      const inner = columns.find('.c-block__columns-column-inner');

      inner.find('.c-block__columns-mixed-btn--ajax').show();

      if (inner.length) {
        $.each(inner, (i, el) => {
          const column = $(el);
          const btnContainer = column.find('.c-block__columns-mixed-btn');

          if (btnContainer.length && btnContainer.hasClass('c-block__columns-mixed-btn--yes-replace') || btnContainer.hasClass('c-block__columns-mixed-btn--yes-append')) {
            const btn = btnContainer.find('.c-btn');
            const type = btnContainer.hasClass('c-block__columns-mixed-btn--yes-replace') ? 'replace' : 'append';

            let loading = false;
            let page = 2;
            let randElements = column.data('block-rand') !== '' ? column.data('block-rand').split(',') : [];

            btn.on('click', e => {
              e.preventDefault();

              if (loading) {
                return;
              }

              column.find('.c-block__columns-column-inner-post').css({
                position: 'relative',
                'z-index': '-999'
              }).stop().animate({
                opacity: 0,
              }, 100);

              btn.stop().animate({opacity: '0.6'}, 100);

              let btnsContainerClass = '';
              let btnsClass = '';
              let btnsText = '';
              let btnsSvg = '';

              if (column.find('.c-block__columns-column-btn').length) {
                btnsContainerClass = column.find('.c-block__columns-column-btn').first().attr('class');
                btnsClass = column.find('.c-block__columns-column-btn').first().find('.c-btn').attr('class');
                btnsText = column.find('.c-block__columns-column-btn').first().find('.c-btn span').text();
                btnsSvg = column.find('.c-block__columns-column-btn svg') ? column.find('.c-block__columns-column-btn').first().find('.c-btn svg').attr('class') : ''
              }

              const data = {
                action: 'block_mixed_columns_load_more',
                column_id: column.data('block-column-id'),
                rand_elements: randElements,
                page: page,
                btns_class: btnsClass,
                btns_container_class: btnsContainerClass,
                btns_text: btnsText,
                btns_svg: btnsSvg,
                block_id: column.closest('.c-block').attr('id'),
                block_key: column.closest('.c-block').data('blok-key'),
              }

              $.post(Utils.localized('ajaxurl'), data)
                .done(response => {
                  if (response[0] == 'ok') {
                    const html = response[1];
                    const isLast = response[2];
                    const newRandElements = response[4];

                    page = isLast ? 1 : page+1;
                    //page += 1;

                    if (isLast) {
                      randElements = [];
                    } else {
                      randElements = [...randElements, ...newRandElements];
                    }

                    if(isLast && type === 'append') {
                      btn.fadeOut(200);
                    }

                    if (type == 'replace') {
                      column.find('.c-block__columns-column-inner-post').remove();
                      column.prepend(html);
                    }else {
                      $(html).insertAfter(column.find('.c-block__columns-column-inner-post').last());
                    }

                    const block = column.closest('.c-block__columns');
                    this.columnsElementsSameHeight( block );

                    if( block.find('.c-block__columns-column-inner--equal').length ) {
                      Utils.equalHeightElements( ['.c-block__columns-column-inner'], block, 768 );
                    }
                  }

                  column.find('.c-block__columns-column-inner-post').css({
                    position: 'relative',
                    'z-index': 1
                  }).stop().animate({
                    opacity: 1,
                  }, 200, () => {
                    btn.stop().animate({opacity: 1}, 200);
                  });

                  if (type === 'replace') {
                    $('html, body').stop().animate({
                      scrollTop: column.offset().top - 100,
                    });
                  }

                  loading = false;
                })
                .fail(() => {});

              loading = true;
            });
          }
        });
      }
    },

    boxesOverlayOutline() {
      const boxes = $('.c-block__boxes');

      if( !boxes.length ) {
        return;
      }

      $.each(boxes, (i, el) => {
        const block = $(el),
          box       = block.find('.c-block__boxes-box-inner');

        $.each(box, (i, el) => {
          const item                = $(el),
            overlay                 = item.find('.c-block__boxes-box-overlay'),
            overlay_bg_color        = overlay.css('background-color'),
            overlay_bg_hover_color  = overlay.attr('data-boxes-overlay-hover'),
            border_color            = item.css('outline-color'),
            border_color_hover      = item.attr('data-boxes-outline-hover');

          item.hover( () => {
            overlay.css({
              'background-color': overlay_bg_hover_color
            });

            item.css({
              'outline-color': border_color_hover
            });
          }, () => {
            overlay.css({
              'background-color': overlay_bg_color
            });

            item.css({
              'outline-color': border_color
            });
          });
        });
      });
    },

    boxes() {
      const boxes = $('.c-block__boxes-box--animate');

      if( !boxes.length ) {
        return;
      }

      const animTime  = 300,
        minHeight = 270,
        gap = 20;

      $.each(boxes, (i, el) => {
        const box   = $(el),
          header    = box.find('.c-block__boxes-box-header'),
          text      = box.find('.c-block__boxes-box-text');

        let headerHeight  = header.outerHeight(true),
          textHeight      = text.outerHeight(true),
          boxHeight       = headerHeight + textHeight > minHeight ? headerHeight + textHeight : minHeight,
          textPos1        = text.position().top,
          headerPos2      = 0,
          textPos2        = 0;

        box.css('min-height', boxHeight);

        text.css({
          'position': 'absolute',
          'top': '100%'
        });
        header.css({
          'position': 'absolute',
          'bottom': 0,
        });

        let headerPos1 = header.position().top;

        header.css({
          'top': headerPos1
        });

        setTimeout( () => {
          headerPos2 = header.position().top,
          textPos2   = text.position().top;
        }, 0);

        $(window).on('resize', () => {
          header.css({
            'top': 'auto'
          });
          text.css({
            'top': '100%'
          });

          headerPos1 = header.position().top;
          header.css({
            'top': headerPos1
          });

          headerHeight  = header.outerHeight();
          textPos1      = headerHeight + gap;

          setTimeout( () => {
            headerPos2    = header.position().top,
            textPos2      = text.position().top;
          });
        });

        box.hover( () => {
          header.stop().animate({
            'top': 0,
            'bottom': 'auto'
          }, animTime);

          text.stop().delay(150).animate({
            'top': textPos1
          }, animTime);
        }, () => {
          header.stop().delay(150).animate({
            'top': headerPos2
          }, animTime);

          text.stop().animate({
            'top': textPos2
          }, animTime);
        });
      });
    },

    blockOverlap() {
      const block = $('.c-block__inner--overlap');

      if( !block.length ) {
        return;
      }

      $.each(block, (i, el) => {
        const self = $(el),
          height = self.outerHeight(),
          overlap = self.attr('data-block-overlap'),
          overlapValue = overlap || 50;

        let margin = $(window).width() < 1025 ? 0 : -(height * (overlapValue / 100)) - 10;

        self.css({
          'margin-bottom': margin,
        });
      });
    },

    video() {
      const videoContainer = $('.c-block__columns-column-video--mp4, .c-block__slider-slide-video--mp4');

      if( !videoContainer.length ) {
        return;
      }

      $.each(videoContainer, (i, el) => {
        const container = $(el),
          video   = container.find('video'),
          playBtn = container.find('.video-play-btn');

        playBtn.hide();

        video.on('click', () => {
          if( video[0].paused ) {
            video[0].play();
          }else {
            video[0].pause();
          }
        });
      });
    },

    listColor() {
      const inlineColorElement = $('span[style*=color]');

      if( !inlineColorElement.length ) {
        return;
      }

      $.each(inlineColorElement, (i, el) => {
        const element = $(el);

        if( element.parent().is('li') ) {
          element.parent().css('color', element.css('color'));
        }
      });
    },

    smoothScroll() {
      const link = $('a');

      link.on('click', function(e) {
        const that = $(this), // eslint-disable-line consistent-this
          href = that.attr('href');

        if( !href ) {
          return;
        }

        const header = $('.c-header');
        let offset = 0;

        if( header.hasClass('c-header--fixed') ) {
          offset = header.outerHeight();
        }

        const hash = href.slice(0,1);

        if( hash == '#' && href.length > 1 && href !== '#main' ) {
          e.preventDefault();

          const section = $(href);
          const hrefNohash = href.slice(1);

          const anchor = $(`a[name='${hrefNohash}']`);
          const anchorAlt = $(`a[name='${href}']`);

          if( !anchor.length && !anchorAlt.length && !section.length || section.hasClass('c-block__tabs-accordion-content') || section.hasClass('c-block__tabs-tab') ) {
            return;
          }

          if (section.length) {
            $('html, body').stop().animate({
              'scrollTop': section.offset().top - offset,
            }, 500);
          } else if (anchor.length) {
            $('html, body').stop().animate({
              'scrollTop': anchor.offset().top - offset,
            }, 500);
          } else if (anchorAlt.length) {
            $('html, body').stop().animate({
              'scrollTop': anchorAlt.offset().top - offset,
            }, 500);
          }
        }
      });
    },

    eventsCalendar() {
      const calendars = $('.c-events-calendar');

      if (!calendars.length) {
        return;
      }

      const calendarRwd = calendar => {
        const view = calendar.fullCalendar('getView');

        if( $(window).width() < 769 ) {
          if( view.name != 'basicWeek' ) {
            calendar.fullCalendar('changeView', 'basicWeek');
            calendar.fullCalendar('option', 'header', {right:'basicWeek,basicDay'});
          }
        }else {
          if( view.name != 'month' ) {
            calendar.fullCalendar('changeView', 'month');
            calendar.fullCalendar('option', 'header', {right:'month,basicWeek,basicDay'});
          }
        }
      }

      $.each(calendars, (i, el) => {
        const calendar = $(el);
        const timeZone = calendar.attr('data-ec-timezone');
        const startOfWeek = calendar.attr('data-ec-firstday') || 1;
        const loader = calendar.find('.c-events-calendar__loader');

        calendar.fullCalendar({
          editable: false,
          header: {
            left: 'prev,next,today',
            center: 'title',
            right: 'month,basicWeek,basicDay',
            themeSystem: 'default'
          },
          // views: {
          //   month: {
          //     displayEventTime: false,
          //   },
          //   day: {
          //     timeFormat: 'h:mm a'
          //   }
          // },
          timezone: timeZone,
          timeFormat: 'h:mm a',
          displayEventTime: false,
          displayEventEnd: false,
          firstDay: startOfWeek,
          lazyFetching: true,
          allDayDefault: true,
          viewRender: () => {
            calendar.find('table').addClass('c-events-calendar__table');
          },
          events: (start, end, timezone, callback) => {
            let events = [];
            const date = calendar.fullCalendar('getDate');

            const data = {
              action: 'load_events',
              date: date.unix()
            };

            calendar.find('table').css('opacity', 0.5);
            loader.show();

            $.post(Utils.localized('ajaxurl'), data)
              .done( (response) => {
                switch(response[0]) {
                case 'ok':
                  $.each(response[1], (i, event) => {
                    let title = event.title.replace(/&#(\d+);/g, function(match, dec) {
                      return String.fromCharCode(dec);
                    }).replace(/&quot;/g, '\"').replace(/&amp;/g, '\&');

                    events.push({
                      title: title,
                      start: $.fullCalendar.moment(event.start, 'YYYY MM DD'),
                      end: $.fullCalendar.moment(event.end, 'YYYY MM DD'),
                      url: event.url,
                    });
                  });
                  calendar.find('table').animate({
                    'opacity': 1
                  }, 300);
                  loader.hide();

                  callback(events);
                  break;
                case 'notfound':
                  break;
                }
              })
              .fail( (error) => {
                console.log(error)
              });
          },
          // eventAfterRender: (event, element) => {
          //   if (event.addtocalendar) {
          //     const ev = $(element);
          //     ev.append(event.addtocalendar);
          //     addtocalendar.load(); // eslint-disable-line no-undef

          //     const calendarTrigger = ev.find('.addtocalendar .atcb-link');

          //     if (calendarTrigger.length) {
          //       calendarTrigger.text('').on('click', (e) => {
          //         const list = $(e.target).parent().find('.atcb-list');

          //         if( calendarTrigger.hasClass('toggle') ) {
          //           list.toggle();
          //         }

          //         calendarTrigger.addClass('toggle');
          //       });
          //     }
          //   }
          // },
          // eventClick: (calEvent, jsEvent, view) => {
          //   if( $(jsEvent.target).hasClass('atcb-link') ) {
          //     return false;
          //   }
          // }
        });

        let debounce;
        $(window).on('resize', () => {
          clearTimeout(debounce);

          debounce = setTimeout( () => {
            calendarRwd(calendar);
          }, 250);
        });

        calendarRwd(calendar);
      });
    },

    init() {
      $(document).ready( () => {
        this.listColor();
        this.columnsBlock();
        this.columnsBlockCarousel();
        this.columnsLoadMore();
        this.eventsCalendar();
        this.columnsAnimationsCustom();
      });

      $(window).on('load', () => {
        this.loadBlocks();
        this.slider();
        this.gallery();
        this.tabs();
        this.accordion();
        this.testimonials();
        this.instagram();
        //this.columns();
        this.boxesOverlayOutline();
        this.boxes();
        this.blockOverlap();
        this.video();
        this.smoothScroll();
      })

      $(window).on('resize', () => {
        this.blockOverlap();
      });
    }
  };

  module.exports = pageBlocksNew;

})(jQuery, window, document);
